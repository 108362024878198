import { plexiRequest } from '.'

export async function getFlexContainers (time) {
  let data
  if (time !== null && time !== undefined) {
    data = plexiRequest({ url: `tia/v1/flexibility/flex_containers/?get_current_status=${time}&get_forecasted_status=${time}` })
  } else {
    data = plexiRequest({ url: 'v1/flexibility/flex_containers/' })
  }
  return data
}

export const getMonitorStation = async (uuid) =>
  // plexiRequest({ url: `monitor/stations/${uuid}` })
  [
    {
      total_station_pwr: {
        total_measured_pwr: '-',
        total_aggregated_pwr: '-',
        total_max_pwr: '-',
        total_min_pwr: '-'
      }
    },
    {
      customers_log: {
        total: '-',
        total_connected: '-',
        total_na: '-',
        total_na_connected: '-',
        total_a: '-',
        total_a_connected: '-',
        total_a_activated: '-'
      }
    },
    {
      past_flex_kpis: [
        {
          avg_flex_activation: {
            value: '-',
            frontend_name: 'Avg violation',
            unit: 'kW',
            description: 'Average flex activation (kW)'
          }
        },
        {
          max_flex_activation: {
            value: '-',
            frontend_name: 'Max flex activation',
            unit: 'kW',
            description: 'Maximum flex activation (kW)'
          }
        },
        {
          flex_activation_duration: {
            value: '-',
            frontend_name: 'Flex activation duration',
            unit: 'h',
            description: 'Number of hours with flex activation (hours)'
          }
        },
        {
          real_violation_duration: {
            value: '-',
            frontend_name: 'Real violation duration',
            unit: 'h',
            description: 'Number of hours with real power violation (hours)'
          }
        }
      ]
    },
    {
      future_flex_kpis: [
        {
          avg_violation: {
            value: '-',
            frontend_name: 'Avg violation',
            unit: 'kW',
            description: 'Average violation when abvove the limit (kW)'
          }
        },
        {
          max_violation: {
            value: '-',
            frontend_name: 'Max violation',
            unit: 'kW',
            description: 'Maximum violation when above the limit (kW)'
          }
        },
        {
          flex_requests_duration: {
            value: '-',
            frontend_name: 'Flex requests duration',
            unit: 'h',
            description: 'Number of hours with flex requestes (hours)'
          }
        },
        {
          flex_deficit_duration: {
            value: '-',
            frontend_name: 'Flex deficit duration',
            unit: 'h',
            description: 'Number of hours with flexibility deficit (hours)'
          }
        }
      ]
    }
  ]

export const getSupplyPoints = async (flexContainer) =>
  plexiRequest({ url: `tia/v1/topology/supply_points/?flex_container_id=${flexContainer}` })

export const getMonitorTransformerPlot = async (id, timeStart, timeEnd, timeMachine) =>
  plexiRequest({ url: `tia/v1/flexibility/flex_containers/${id}/metrics/?start=${timeStart}&middle=${timeMachine}&finish=${timeEnd}` })

export const getMonitorSupplyPointPlot = async (id, timeStart, timeEnd) =>
  plexiRequest({ url: `tia/v1/metrics/supply_point_metrics/?start=${timeStart}&finish=${timeEnd}&supply_point_id=${id}` })

export const getDoeSupplyPointPlot = async (id, timeStart, timeEnd) =>
  plexiRequest({ url: `tia/v1/flexibility/dynamic_operating_envelopes/?start=${timeStart}&finish=${timeEnd}&supply_point_id=${id}` })

export const changeDataFlexContainer = async (id, field, value) => {
  const body = new window.FormData()
  body.append(field, value)

  plexiRequest({
    url: `tia/v1/flexibility/flex_containers/${id}`,
    method: 'patch',
    data: body
  })
}
export async function getMonitorFSPPlot (uuid, timeStart, timeEnd, FSP, timeMachine) {
  let data
  if (timeMachine !== null) {
    data = plexiRequest({ url: `monitor/fsp/series/${uuid}?TIME_START=${timeStart}&TIME_END=${timeEnd}&FSP=${FSP}&TIME_MACHINE=${timeMachine}` })
  } else {
    data = plexiRequest({ url: `monitor/fsp/series/${uuid}?TIME_START=${timeStart}&TIME_END=${timeEnd}&FSP=${FSP}` })
  }
  return data
}

export const getMonitorLogs = async (id, timeStart, timeEnd) =>
  plexiRequest({ url: `tia/v1/api/flex_containers/${id}/reservations?start=${timeStart}&finish=${timeEnd}` })

export const getMonitorSolutions = async (uuid, period, FSP, id) =>
  plexiRequest({ url: `monitor/fsp/logs/${uuid}?PERIOD=${period}&FSP=${FSP}&ID=${id}` })

export const changeStationFlex = async (uuid, value) =>
  plexiRequest({
    url: `map/stations/update_flex_status/${uuid}?is_flex=${value}`,
    method: 'get'
  })
