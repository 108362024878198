<template>
  <div>
    <v-app-bar
      v-if="!hidden"
      color="#27272777"
      dense
      dark
    >
      <v-row class="nav-bar-map-tia">
        <v-col cols="1" />
        <v-col cols="6">
          <TIAMonitoringDatesSelector
          :type="type"
           />
        </v-col>

        <v-col class="m-top-10">
          <TIAMonitoringSearchBox
            v-if="listElements"
            :type="type"
            :stations="getList()"
            @selectElement="selectsupplyPoint"
          />
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>

<script>
import { mdiChevronDoubleDown, mdiChevronDoubleUp } from '@mdi/js'
import TIAMonitoringSearchBox from '@/components/TIA/TIAMonitoring/TIAMonitoringSearchBox.vue'
import TIAMonitoringDatesSelector from '@/components/TIA/TIAMonitoring/TIAMonitoringDatesSelector.vue'
export default {
  name: 'TIAMonitoringNavBarMap',
  components: {
    TIAMonitoringSearchBox,
    TIAMonitoringDatesSelector
  },
  props: {
    listElements: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: ''
    }

  },
  data: () => ({
    ChevronUp: mdiChevronDoubleUp,
    ChevronDown: mdiChevronDoubleDown,
    show: true,
    hidden: false
  }),


  methods: {

    selectElement (station) {
      this.$emit('selectElement', station)
    },
    selectSupplyPoint (supplyPoint) {
      // this.$emit('selectElement', supplyPoint)
    },

    toggleNavbar (value) {
      this.hidden = value
      this.$emit('toggleNavbar')
    },

    getList () {
      try {
        const stationsArray = []
        this.listElements.forEach(station => {

        stationsArray.push({ id: station.id, name: station.name })

        })
        return stationsArray
      } catch (error) {
        console.log(error)
        this.error = true
      }
    }
  }
}
</script>

<style>
.nav-bar-map-tia{
  margin-bottom: 0px;
}
</style>
