import Vue from 'vue'
import { Icon } from 'leaflet'
import store from './store'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import VueMeta from 'vue-meta'
import globalVariables from '@/assets/globalVariables/global'

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import 'vue-easytable/libs/theme-dark/index.css'
import { VeTable, VePagination } from 'vue-easytable'

import Papa from 'papaparse' // Import PapaParse

Vue.component('VueSlider', VueSlider)

Vue.use(VeTable)
Vue.use(VePagination)
Vue.use(VueMeta)
Vue.use(Papa)

Vue.prototype.$sessionStorage = new Vue({
  data: {
    logged: window.sessionStorage.getItem('logged'),
    zoneId: window.sessionStorage.getItem('zoneId'),
    gridElement: window.sessionStorage.getItem('gridElement'),
    theme: window.sessionStorage.getItem('theme'),
    timeZone: window.sessionStorage.getItem('timeZone'),
    projectCoordSystem: window.sessionStorage.getItem('projectCoordSystem'),
    userInfo: window.sessionStorage.getItem('userInfo')
  },
  watch: {
    logged (value) {
      console.log(window.location.href)
      window.sessionStorage.setItem('logged', value)
    },
    zoneId (value) {
      value
        ? window.sessionStorage.setItem('zoneId', value)
        : window.sessionStorage.removeItem('zoneId')
    },
    gridElement (value) {
      value
        ? window.sessionStorage.setItem('gridElement', value)
        : window.sessionStorage.removeItem('gridElement')
    },
    theme (value) {
      window.sessionStorage.setItem('theme', value)
    },
    timeZone (value) {
      window.sessionStorage.setItem('timeZone', value)
    },
    projectCoordSystem (value) {
      window.sessionStorage.setItem('projectCoordSystem', value)
    },
    userInfo (value) {
      window.sessionStorage.setItem('userInfo', value)
    }
  }
})

Vue.config.productionTip = false
Vue.config.silent = true

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: (h) => h(App)
}).$mount('#app')
