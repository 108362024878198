export default {
  appname: 'App-namn',
  lang: 'Språk',
  ok: 'Okej',
  add: 'Lägg till',
  cancel: 'Avbryt',
  close: 'Stäng',
  remove: 'Ta bort',
  delete: 'Radera',
  delete_all: 'Radera alla',
  open: 'Öppna',
  new: 'Ny',
  edit: 'Edit',
  reset: 'Återställ',
  create: 'Skapa',
  confirm: 'Bekräfta',
  name: 'Namn',
  measurements: 'Mått',
  events: 'Evenemang',
  select_all: 'Välj alla',
  select_by_id: 'Välj efter id',
  email: 'E-post',
  grid_inspector_title: 'Grid Inspector',
  digital_twin: 'Digital Twin',
  grid_monitor_title: 'Grid Monitor',
  tia_map_title: 'TIA karta',
  fetching: 'Hämta data',
  plots: 'Plottar',
  plot_one: 'Plot',
  info: 'Info',
  phase: 'Fas',
  unit: 'Enhet',
  power: 'Makt',
  description: 'Avskrivning',
  optional: 'Valfri',
  no_data_available: 'Inga data tillgängliga',
  consumption: 'Konsumtion',
  generation: 'Generation',
  start_date: 'Startdatum',
  end_date: 'Slutdatum',
  creation_date: 'Creation date',
  expiration_date: 'Expiration date',
  installation_date: 'Datum för installation',
  mode: 'Läge',
  and: 'y',
  type: 'Typ',
  click_to_change: 'Klicka för att ändra',
  voltage: 'Spänning',
  current: 'Nuvarande',
  apparent_power: 'Apparent effekt',
  active_power: 'Aktiv kraft',
  reactive_power: 'Reaktiv effekt',
  losses: 'Förluster',
  heatmap: 'Värmekarta',
  cable: 'Kabel',
  length: 'Längd',
  sync_map: 'Synkronisera karta',
  formula: 'Formel',
  hours: 'Timmar',
  not_available: 'Ej tillgänglig',
  no_info_available: 'Ingen information tillgänglig',
  seconds: 'sekunder',
  second_abb: 's',
  file: 'Fil',

  validations: {
    item_required: 'Obligatoriskt fä',
    equal_or_greater: 'Objektet ska vara lika med eller större än',
    item_between: 'Objektet bör vara mellan',
    integer: 'Posten bör vara heltal',
    number: 'Posten måste vara ett nummer.',
    not_special_character: 'Specialtecken är inte tillåtna'
  },

  login: {
    password: 'Lösenord',
    login: 'Inloggning',
    error_message: 'Du har angett fel e-postadress eller lösenord.'
  },

  grid_set: {
    title: 'Ställ in visualiseringsområdet',
    by_area: 'Per område',
    by_element: 'Av element',
    set_area: 'Ställ in området',
    completed_fields: 'Färdiga fält',
    area_level: 'Områdesnivå',
    error: 'Ett fel inträffade vid åtkomst till data. Försök igen.',
    type_element: 'Typ av element*',
    element_id: 'Elementets id*',
    element_name: 'Namn på elementet*',
    radius: 'Radie',
    getting_grid_msg: 'Hämta data Området'
  },

  grid: {
    network: 'Nätverk',
    networks: 'Nätverk',
    meter: 'Mätare',
    meters: 'Mätare',
    station: 'station',
    stations: 'Stationer',
    line: 'Linje',
    lines: 'Linjer',
    feeder: 'Feeder',
    feeders: 'Feeders',
    connection_point: 'Anslutningspunkt',
    connection_points: 'Anslutningspunkter',
    transformer: 'Transformator',
    transformers: 'Transformatorer',
    supervisors: 'Tillsynsmyndigheter',
    advanced_supervisors: 'Avancerade tillsynsorgan',
    supply_points: 'Leveranspunkts',
    smart_meters: 'Smarta mätare',
    empty_grid: 'Det finns inga tillgängliga rutnätsdata att visa.'
  },

  nav_bar: {
    title: 'Hem',
    configure: 'Konfigurera ditt nätverk',
    change_password: 'Ändra lösenord',
    logout: 'Utloggning',
    load_data: 'Data om belastning',
    set_grid: 'Ställ in området',
    admin_panel: 'Administratörspanel'
  },

  admin: {
    confirm_title: 'Är du säker?',
    confirm_message: 'Användaren raderas. Är du säker?',
    user_deleted: 'Användaren har tagits bort',
    reset_password: 'Återställ lösenordet',
    delete_user: 'Ta bort användare',
    user_selected: 'Användaren har valt:',
    indication: 'anger ett obligatoriskt fält',
    department: 'Avdelning',
    username: 'Användarnamn',
    email_hint: 'Det kommer att vara din e-postadress för åtkomst.',
    last_name: 'Efternamn',
    last_name_hint: 'Ditt efternamn',
    first_name: 'Förnamn',
    first_name_rule: 'Förnamn är ett krav.',
    add_user: 'Lägg till användare',
    company: 'Företag',
    last_login: 'Senaste inloggning',
    reset_password_message: 'Återställning av lösenordet. Skickar det till användaren.',
    add_user_message: 'Användare som skapats med detta lösenord. Kopiera det och ange din e-postadress och detta lösenord.',
    rows_per_page: 'Rader per sida'
  },

  grid_surveyor: {
    loading_grid_surveyor_msg: 'Ladda instrumentpanelen',
    lines: 'Linjer',
    connection_points: 'Anslutningspunkter',
    fuses: 'Säkringar',
    clients: 'Kunder'
  },
  substations: {
    station_count: 'Antal stationer',
    transformer_count: 'Antal transformatorer',
    station_count_per_power: 'Antal stationer per effekt (kVA)',
    station_count_per_feeder_count: 'Antal stationer per antal matare',
    transformer_count_per_feeder_count: 'Antal transformatorer per antal matare',
    transformer_count_per_power: 'Antal transformatorer per effekt (kVA)',
    installed_stations_per_year: 'Installerade stationer per år',
    connection_per_station: 'Anslutningar per station',
    clients_per_station: 'Kunder per station',
    km_per_station: 'Kilometerlängd per station'
  },
  lines: {
    km_of_line: 'Km linje',
    km_per_station: 'Km per Station',
    m_per_connection: 'M per anslutning',
    m_per_client: 'M per kund',
    peak_current: 'Toppström',
    underground: 'Underjordisk',
    overhead: 'Överliggande'
  },
  connection: {
    connection_points: 'N. Anslutningspunkter',
    avg_connection_points_per_station: 'Genomsnittliga anslutningspunkter per station',
    avg_meters_per_connection_points: 'Genomsnittliga mätare per anslutningspunkt'
  },
  fuses: {
    fuses_count: 'N. Säkringar',
    feeders_count: 'N. Matare',
    maneuvers_count: 'N. Manövrar'
  },
  clients: {
    clients_count: 'N. Kunder'
  },

  map_nav_bar: {
    hide_bar: 'Dölj verktygsfält',
    show_bar: 'Visa verktygsfält',
    network_mode: 'Nätverksläge',
    element_mode: 'Elementläge',
    change_to_network_mode: 'Ändra till nätverksläge',
    change_to_element_mode: 'Ändra till elementläge'
  },
  map: {
    sld: 'Diagram med en enda linje',
    map_view: 'Visa kartan'
  },
  map_info: {
    area: 'Område',
    volt_level: 'Volt. Nivå',
    low: 'Låg',
    coordinates: 'Koordinater',
    element_hovered: 'Elementet svävar'
  },

  grid_inspector: {
    loading_msg: 'Loading Grid Inspector',
    raw: 'Raw View',
    connectivity: 'Connectivity View',
    hosting_capacity: 'Hosting Capacity View',
    hcm_generation: 'Generation',
    hcm_consumption: 'Consumption'
  },
  grid_panel: {
    general_information: 'General information',
    meters_information: 'Meters information',
    transformer_information: 'Transformer information',
    code: 'Code',
    max_current: 'Max. current',
    num_stations: 'N.Stations',
    num_transformers: 'N.Transformers',
    num_connection_points: 'N.Connection Points',
    num_clients: 'N.Clients',
    total_length_conductor: 'Total length conductor',
    tap_max: 'Tap max',
    tap_min: 'Tap min',
    tap_positions: 'Tap positions',
    voltage_regulator: 'Voltage regulator',
    num_station_sup: 'N.Station supervisors',
    num_line_sup: 'N.Line supervisors',
    num_lines: 'N.Lines',
    num_active_lines: 'N.Active lines'
  },

  hcm: {
    loading_msg: 'Lastning Hosting Capacity Map',
    consumption: 'Konsumtion',
    not_available: 'Ej tillgänglig',
    reserve_kw: 'Reserve (kW)',
    reserves: 'Reservationer',
    consumption_hc: 'Konsumtionskapacitet',
    generation_hc: 'Generering värdkapacitet',
    consumption_reserves: 'Konsumtionsreserver',
    generation_reserves: 'Generation Reserves',
    full_reserve: 'Fullständig reservatione',
    hc_id: 'Kapacitet id',
    available_capacity: 'Tillgänglig kapacitet',
    reserve_period_month: 'Reservationsperiod (månader)',
    months: 'månader',
    description_optional: 'Avskrivning (Vvalfri)',
    worst_case_txt: 'Visa värsta tänkbara fall',
    not_available_hcm: 'Värdkraftskartan är endast tillgänglig för lågspänning',
    delete_confirm_reserve: 'Är du säker på att du vill ta bort denna reserv?',
    delete_reserve_error: 'Ett fel uppstod när reserven skulle raderas. Försök igen.',
    add_reserve_error: 'Ett fel uppstod när reserv lades till. Försök igen.',
    warning: 'Hostingkapaciteten är inte tillgänglig för nät med mer än 5000 nätverk.',
    warning_link: 'Välj här en mindre yta.'
  },

  dt: {
    digital_twin_project: 'Digitalt tvillingprojekt',
    my_projects: 'Mina projekt',
    new_connections: 'Nya förbindelser',
    planning: 'Planering',
    loading_projects: 'Lastning av projekt',
    simulation_date: 'Datum för simulering',
    number_of_cases: 'Antal fall',
    penetration: 'penetration',
    created_on: 'Skapad den',
    basic_info: 'Grundläggande information',
    select_area: 'Välj område',
    power_constraints: 'Begränsningar i fråga om elkvalitet',
    max_voltage_limit: 'Max. Spänningsgräns',
    min_voltage_limit: 'Min. Spänningsgräns',
    max_voltage_unbalance: 'Max. Spänningsobalans',
    max_transformer_loading: 'Max. Transformatorbelastning',
    max_current_on_lines: 'Max. Ström på linjerna',
    or: 'eller',
    pvs_settings: 'PVs Inställningar',
    percentage_of_customers_with_pv: 'Procentuell andel kunder med solceller',
    initial_pv: 'Initalt genomslag av solcellseffekt i LV-systemet',
    pv_power_steps: 'Stegen för PV-effektsteg för simulering',
    evs_settings: 'Inställningar för EV:s',
    ev_nominal_power: 'EV-laddare nominell effekt',
    ev_battery_capacity: 'EV:s genomsnittliga batterikapacitet',
    ev_daily_distance: 'EV dagliga distansresor: Medelvärde',
    ev_power_factor: 'EV-laddarens effektfaktor',
    hps_settings: 'HPs inställningar',
    n_hps: 'Antal värmepumpar',
    hp_nominal_power: 'Värmepumpens nominella effekt',
    hp_nominal_factor: 'Värmepumpens nominella faktor',
    hp_cop: 'Prestandakoefficient för uppvärmning (COP)',
    generating_dt: 'Skapa en digital tvilling',
    opening_dt: 'Öppning av digital tvilling',
    delete_confirm_project: 'Är du säker på att du vill ta bort det här projektet?',
    back_to_form: 'Tillbaka till formuläret',
    loading_dt: 'Laddar Digital Twin',
    project: 'Projekt',
    select_project: 'Välj projekt',
    simulate: 'Simulera',
    step_time: 'Steg Tid',
    created_date: 'Skapat datum',
    base_power: 'Baskraft',
    project_info: 'Information om projektet Digital Twin',
    remove_client: 'Ta bort klienten',
    client_removed: 'Klienten har tagits bort framgångsrikt',
    change_phase: 'Ändra fas',
    phase_connection: 'Fasanslutning',
    no_meters_in_connection_point: 'Det finns inga mätare i denna anslutningspunkt',
    active: 'Aktiv',
    reactive: 'Reaktiv',
    n_pv: 'Antal solcellssystem',
    power_factor: 'Effektfaktor',
    ev_cp: 'Elbilsladdarens effekt (kW)',
    ev_ec: 'Energikapacitet för elbilar(kWh)',
    ev_cpf: 'Effektfaktor för EV-laddare',
    ev_t0: 'EV möjlig laddning t0',
    ev_t1: 'EV möjlig laddning t1',
    ev_mean: 'EV genomsnittligt antal km per dag',
    daily_km: 'Antal km per dag STD (%)',
    peak_power: 'Toppeffekt för varje system (kW)',
    pnom_hp: 'Pnom. för varje värmepump (kW)'
  },
  new_connections: {
    editing: 'Redigering av',
    new_case: 'Nytt fall',
    case_name: 'Fallets namn',
    reference_case: 'Välj referensfall',
    uniform_load_scale: 'Enhetlig belastningsskala',
    add_element: 'Lägg till element',
    add_pv: 'Lägg PV',
    add_hp: 'Lägg HP',
    add_ev: 'Lägg EV',
    add_ess: 'Lägg ESS',
    add_custom_profile: 'Lägg till anpassad profil',
    add_conn_point: 'Lägg till anslutningspunkt',
    remove_client: 'Ta bort klient',
    change_phase: 'Förändringsfas',
    disable: 'Disable',
    pv_conn_params: 'Parametrar för anslutning av PV',
    n_pvs: 'Antal PVs',
    phase_connection: 'Anslutningsfas',
    installed_peak_power: 'Installerad toppeffekt',
    max_q_injection: 'Maximum reactive power injection',
    ev_conn_params: 'Parametrar för anslutning av EV',
    n_evs: 'Antal laddare för EVs',
    std: 'STD',
    ev_charging_time: 'EV charging time range (hours)',
    hp_conn_params: 'Parametrar för anslutning av HP',
    hp_power_factor: 'Effektfaktor för värmepump',
    current_changes: 'Aktuella förändringar',
    hide_details: 'Dölj detaljer',
    see_details: 'Se detaljer',
    edit_element: 'Redigera element',
    quantity: 'Antal',
    changes: 'Förändringar',
    simulating_case_msg: 'Skapa och simulera fall',
    line_full: 'Linjen är full av anslutningspunkter',
    toggle_state: 'Växla tillstånd',
    confirm_toggle_state__msg: 'Du kommer att ändra status för detta element. Är du säker på det?',
    new_connection_point: 'Ny anslutningspunkt',
    max_real_p: 'Maximal verklig effekt (kW)',
    num_custom_profiles: 'Antal anpassade profiler',
    time_sampling: 'Provtagning av tid',
    ess_mode: 'Läge',
    ess_node: 'Nod',
    ess_phase_conn: 'Phase Connection',
    ess_sigid: 'ID för element',
    ess_sigmode: 'Strömflödets riktning',
    ess_pmaxin: 'Maximal aktiv effekt som kan förbrukas',
    ess_pmaxout: 'Maximal aktiv effekt som kan injiceras',
    ess_qmaxin: 'Maximal reaktiv effekt som kan absorberas',
    ess_qmaxout: 'Maximal reaktiv effekt som kan injiceras',
    ess_emax: 'Maximalt antal kilowattimmar',
    min_state_charge: 'Minsta laddningsstatus i %',
    max_state_charge: 'Maximal laddningsstatus i %',
    current_state_charge: 'Nuvarande laddningsstatus i %',
    constant_active_power: 'Konstant aktiv effekt i kw (PQ-läge)',
    constant_reactive_power: 'Konstant reaktiv effekt i kvar (voltstyrning)',
    volt_control: 'Voltkontroll',
    voltage_regulation_set_point: 'Börvärde spänningsreglering',
    lower_power_limit: 'Nedre effektgräns i kW (peakshave)',
    upper_power_limit: 'Övre effektgräns i kW (peakshave)',
    band_power_limit: 'Effektgräns i kW för att utlösa',
    mode_and_phase: 'Läge och fas',
    monitored_element: 'Kontrollerat element',
    max_energy_capacity: 'Maximal energikapacitet',
    state_charge: 'Laddningsstatus',
    constant_active_reactive_power: 'Konstant aktiv och reaktiv effekt',
    voltage_var_parameters: 'Voltage VAR Parameters',
    voltage_regulation_point: 'Börvärde för spänningsreglering',
    trigger_limit: 'Trigger Gräns',
    input_less_or_equal_to_high: 'Minimivärdet måste vara mindre än eller lika med maximivärdet',
    input_greater_or_equal_to_low: 'Det maximala värdet måste vara större än eller lika med det minimala värdet',
    peak_shave_description: 'Peak Shave: Styr effekten som flödar nedströms i den ledning som installerats uppströms från den valda anslutningspunkten'
  },
  planning: {
    project_name: 'Projektnamn',
    violation: 'Överträdelse',
    violation_percentage: 'Överträdelseprocent',
    penetration_level: 'Penetrationsnivå',
    level_of_penetration: 'Nivå av penetration',
    installed_capacity: 'Installed capacity',
    total_capacity: 'Total installerad kapacitet',
    phA_undervoltage: 'fasA underspänning',
    phB_undervoltage: 'fasB underspänning',
    phC_undervoltage: 'fasC underspänning',
    phA_overvoltage: 'fasA överspänning',
    phB_overvoltage: 'fasB överspänning',
    phC_overvoltage: 'fasC överspänning',
    voltage_negative_sequence: 'Spänning negativ sekvens',
    voltage_zero_sequence: 'Spänning nollsekvens',
    phA_overcurrent: 'fasA överström',
    phB_overcurrent: 'fasB överström',
    phC_overcurrent: 'fasC överström',
    phN_overcurrent: 'fasN överström',
    overloading_transformer: 'Överbelastad transformator',
    pv_info_montecarlo_simulation_quantity: 'Det kommer att köras 10 montecarlo-simuleringar, var och en bestående av 500 kraftflödessimuleringar vardera på 24 timmar med datum som anges ovan.',
    pv_info_simulation_increased: 'I varje simulering kommer den totala installerade toppeffekten (kWp) i nätet att ökas med starteffekt och stegeffekt enligt nedan.',
    pv_info_power_equally_shared: 'Effekten kommer att delas lika mellan de slumpmässigt tilldelade kunderna, men alltid uppfylla den penetrationsnivå som definieras nedan och bibehålla deras fasanslutning.',
    pv_info_results_may_change: 'Resultaten kan ändras beroende på vald dag för simuleringen på grund av inverkan av irradiansprofiler.',
    ev_info_montecarlo_simulation_quantity: 'Det kommer att köras 10 montecarlo-simuleringar, var och en bestående av 500 kraftflödessimuleringar vardera på 24 timmar på datumet som anges ovan.',
    ev_info_simulation_increased: 'I varje simulering kommer elbilarnas penetration i näten att ökas (%). Den kommer att börja med 10 % penetration och sluta med 100 %.',
    ev_info_same_charger: 'Samma EV-laddare med laddningsmönster som uppfyller villkoren nedan kommer att installeras hos slumpmässiga kunder som uppfyller den angivna penetrationen i varje simulering.'
  },
  kpi: {
    apparent_power_avg: 'Skenbar effekt: genomsnitt)',
    apparent_power_phase_share: 'Skenbar effekt: Fas Andel (genomsnitt)',
    capacity_factor: 'Kapacitetsfaktor',
    current_avg: 'Ström: genomsnitt',
    current_max: 'Ström: Max',
    current_min: 'Ström: Min',
    current_unbalance_avg: 'Ström: Obalans (genomsnitt)',
    current_unbalance_rate: 'Ström: Obalans (hastighet)',
    energy_losses: 'Energiförluster',
    over_utilization_rate: 'Överutnyttjandegrad',
    overload_rate: 'Överbelastningsgrad',
    ev: 'Elektriska fordon',
    pv_systems: 'Fotovoltaiska system',
    hps: 'Värmepumpar',
    load_factor: 'Belastningsfaktor',
    power_factor_avg: 'Power Factor (Avg)',
    voltage_unbalance_avg: 'Voltage Unbalance (Avg)',
    voltage_unbalance_rate: 'Voltage Unbalance (Rate)',
    voltage_qualified_rate: 'Voltage: Qualified Voltage (Rate)',
    voltage_overvoltage: 'Voltage: Overvoltage (Rate)',
    voltage_undervoltage: 'Voltage: Undervoltage (Rate)',
    voltage_min: 'Voltage: Min',
    voltage_max: 'Voltage: Max',
    voltage_avg: 'Voltage: Avg',
    ldc: 'Kurva för lastens varaktighet',
    ldc_current: 'Varaktighetskurva för belastning: Ström',
    ldc_power: 'Varaktighetskurva för belastning: Effekt',
    unbalanced_factor: 'Obalanserad faktor',
    unbalance_factor_current: 'Obalansfaktor Ström',
    unbalance_factor_Voltage: 'Obalansfaktor Spänning',
    vl: 'Spänning Längd',
    energy_supplied_station: 'Levererad energi (från station)',
    energy_supplied_pv: 'Levererad energi (från solceller)',
    duration_p_out_max: 'P Out Max från abonnemangspunkt: Händelser',
    duration_p_in_max: 'P In Max från abonnemangspunkt: Händelser',
    duration_q_out_max: 'Q Out Max från abonnemangspunkt: Händelser',
    duration_q_in_max: 'Q In Max från abonnemangspunkt: Händelser',
    power_p_out_max: 'P Out Max från abonnemangspunkt: Kraft',
    power_p_in_max: 'P In Max från abonnemangspunkt: Kraft',
    power_q_out_max: 'Q Out Max från abonnemangspunkt: Kraft',
    power_q_in_max: 'Q In Max från abonnemangspunkt: Kraft'

  },
  plot: {
    phase_a: 'FasA',
    phase_b: 'FasB',
    phase_c: 'FasC',
    phase_n: 'FasN',
    power_kw: 'Power(kW)',
    voltage_v: 'Voltage(V)',
    current_a: 'Current(A)',
    time_h: 'Tid(h)',
    subscription_limit: 'Prenumerationsgränser',
    consumption_limit: 'Förbrukning för uppströms nätgräns',
    injection_limit: 'Injektion till uppströms nätgräns',
    download: 'Ladda ner',
    export_data: 'Exportera dataplot',
    select_phase_export: 'Välj faser för att exportera'
  },

  importer: {
    title: 'Filimportör',
    reference_column: 'Referenskolumn',
    time_column: 'Tidskolumn',
    signal: 'Signal',
    measurement_column: 'Mätningskolonn',
    load_file: 'Ladda fil',
    measurement_file_sent: 'Mätningsfil skickad',
    topology: 'Topologi',
    custom_profile: 'Anpassad profil',
    parsing_file: 'Tolkning av fil',
    uploading_file: 'Uppladdning av fil',
    only_csv_files_allowed: 'Endast .csv-filer tillåtna',
    at_least_two_columns: 'Minst två kolumner (aktiv och reaktiv)',
    unit_expected: 'Förväntad enhet',
    expected_values_between: 'Värdena bör ligga i intervallet mellan',
    negative_values_grid_injection: 'Negative values correspond to generation/grid injection',
    positive_values_consumption: 'Positiva värden motsvarar förbrukning',
    information_file: 'Information i fil',
    has_been_successfully_uploaded: 'har laddats upp framgångsrikt',
    failed_uploading_file: 'Misslyckades med att ladda upp filen',
    error_between_lines: 'Fel mellan rader'
  },

  grid_monitor: {
    select_all_stations: 'Välj alla stationer',
    empty_tree: 'Inga uppgifter finns tillgängliga. Välj ett giltigt alternativ för att se informationen här',
    search: 'Sök på',
    assets: 'Tillgångar',
    forecast: 'Prognos',
    date_start: 'Datum för start',
    date_end: 'Slutdatum',
    aggregation: 'Aggregering',
    aggregation_name: 'Aggregationens namn',
    enter_name: 'Ange ett namn',
    operator: 'Operatör',
    send_to_plot: 'Skicka till Plot',
    sending: 'Sändning...',
    total: 'Totalt',
    variables: 'Variabler',
    expression_calculations: 'Beräkningar av uttryck',
    operation_between_factor: 'Drift mellan faktor',
    operation: 'Drift',
    addition: 'Tillägg',
    subtraction: 'Subtraktion',
    multiplication: 'Multiplikation',
    division: 'Division',
    factor: 'Faktor',
    actions: 'Åtgärder',
    draw: 'Drag',
    expression: 'Expression',
    depth: 'Djup',
    sampling_time: 'Tidsprovtagning'
  }
}
