<template>
  <div v-if="loadingSelf.isVisible">
    <CMLoader
      :loading="loadingSelf.isVisible"
      :message="loadingSelf.message"
    />
  </div>
  <splitpanes v-else-if="loadingSelf.isVisible == false">
    <pane
      min-size="60"
      max-size="60"
      size="60"
    >
      <div>
        <TIAMonitoringNavBarMap
          :type="flexContainer"
          :list-elements="stations"
          @toggleLoading="toggleLoading"
          @selectElement="selectElement"
          @toggleNavbar="toggleNavbar"
        />
      </div>
      <div v-if="loadingPlots.isVisible">
        <CMLoader
          :loading="loadingPlots.isVisible"
          :message="loadingPlots.message"
        />
      </div>
      <splitpanes
        v-else-if="loadingPlots.isVisible == false"
        horizontal
        :push-other-panes="false"
        style="height: 84vh"
      >
        <pane
          min-size="42"
          max-size="42"
          size="42"
        >

          <TIAMonitoringPlotStation
            v-if="monitorStationPlot"
            :data="monitorStationPlot"
          />
        </pane>
        <pane
          min-size="60"
          max-size="60"
          size="60"
        >
        <div>
          <TIAMonitoringSupplyPointsNavBar
          v-if="supplyPoints.length > 0"
          :supply-point="supplyPoints[0].name"/>
          <div>
        <TIAMonitoringNavBarMap
          :type="supplyPoint"
          :list-elements="supplyPoints"
          @toggleLoading="toggleLoading"
          @selectElement="selectElement"
          @toggleNavbar="toggleNavbar"
        />
      </div>
        </div>
          <TIAMonitoringPlotSupplyPoint
            v-if="monitorSupplyPointPlot"
            :data="monitorSupplyPointPlot"
            :doe="doeSupplyPointPlot"
          />
        </pane>
      </splitpanes>
    </pane>
    <pane
      min-size="50"
      max-size="50"
      size="50"
      class="tia-monitoring-kpis"
    >
      <v-tabs
        v-model="tab"
        :height="25"
        dark
        fixed-tabs
        class="yellowPlexi"
      >
        <v-tab
          :height="25"
          :value="0"
          @click="clickTabKPIs()"
        >
          KPIS
        </v-tab>
        <v-tab
          :height="25"
          :value="1"
          @click="clickTabEvents()"
        >
          EVENTS
        </v-tab>

        <v-tab-item :value="0">
          <TIAMonitoringKpis            
            :monitor-station="monitorStation"
            :max-power="maxPowerFlexContainer"
          />
        </v-tab-item>
        <v-tab-item :value="1">
          <TIAMapEventsStation
            :events="events"
            @getLogs="getLogs"
          />
        </v-tab-item>
      </v-tabs>
    </pane>
  </splitpanes>
</template>

<script>
import CMLoader from '@/components/Common/CMLoader.vue'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import TIAMonitoringKpis from '@/components/TIA/TIAMonitoring/TIAMonitoringKpis.vue'
import TIAMonitoringPlotStation from '@/components/TIA/TIAMonitoring/TIAMonitoringPlotStation.vue'
import TIAMonitoringPlotSupplyPoint from '@/components/TIA/TIAMonitoring/TIAMonitoringPlotSupplyPoint.vue'
import { getMonitorLogs } from '@/services/TIA'
import { getSupplyPoints, getMonitorSupplyPointPlot, getDoeSupplyPointPlot } from '@/services/TIA'
import { mapState } from 'vuex'
import getLocalDateFormated from '@/mixins/TIA/getLocalDateFormated'
import checkTimeMachine from '@/mixins/TIA/checkTimeMachine'
import TIAMapEventsStation from '@/components/TIA/TIAMap/TIAMapEventsStation.vue'
import TIAMonitoringNavBarMap from '@/components/TIA/TIAMonitoring/TIAMonitoringNavBarMap.vue'
import TIAMonitoringSupplyPointsNavBar from '@/components/TIA/TIAMonitoring/TIAMonitoringSupplyPointsNavBar.vue'

export default {
  name: 'TIAMonitoringLayout',

  components: {
    Splitpanes,
    Pane,
    TIAMonitoringKpis,
    TIAMonitoringPlotStation,
    TIAMonitoringPlotSupplyPoint,
    CMLoader,
    TIAMapEventsStation,
    TIAMonitoringNavBarMap,
    TIAMonitoringSupplyPointsNavBar
  },
  mixins: [getLocalDateFormated, checkTimeMachine],
  props: {
    monitorStation: {
      type: Array,
      default: () => []
    },
    monitorStationPlot: {
      type: Array,
      default: () => []
    },
    stations: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    monitorSupplyPointPlot: null,
    doesSupplyPointPlot: null,
    monitorBilateralPlot: null,
    monitorNonFirmPlot: null,
    events: [],
    solutions: [],
    supplyPoints: [],
    loadingSelf: {
      isVisible: null,
      message: 'Getting data TIA Monitoring'
    },
    loadingPlots: {
      isVisible: null,
      message: 'Getting data Plots'
    },
    tab: 0,
    tabPlot: null,
    supplyPoint: 'supplyPoint',
    flexContainer: 'flexContainer',
    maxPowerFlexContainer: null,
  }),

  computed: {
    ...mapState({
      supplyPointSelected: (state) => state.TIA.TIAMonitoring.supplyPointSelectedId,
      periodSelected: (state) => state.TIA.TIAMonitoring.periodSelected,
      tabTIAMonitoring: (state) => state.TIA.TIAMonitoring.tabTIAMonitoring,
      timeMachine: (state) => state.TIA.timeMachine,
      maxPowerStation: (state) => state.TIA.TIAMap.maxPowerStation
    })
  },

  watch: {
    tabTIAMonitoring () {
      this.tab = this.tabTIAMonitoring
    },
    maxPowerStation () {
      this.maxPowerFlexContainer = this.maxPowerStation
    },
    supplyPointSelected () {
      this.showLoadingPlots(true)
      this.getDataSupplyPoint()
      setTimeout(() => { this.showLoadingPlots(false) }, 800)
    },
    periodSelected () {
      this.showLoadingPlots(true)
      this.getDataSupplyPoint()
      setTimeout(() => { this.showLoadingPlots(false) }, 800)
    },
    timeMachine () {
      this.showLoadingPlots(true)
      this.getDataSupplyPoint()
      setTimeout(() => { this.showLoadingPlots(false) }, 500)
    }
  },
  mounted () {
    this.maxPowerFlexContainer = this.$store.state.TIA.TIAMap.maxPowerStation    
    this.showLoading(true)
    this.getListSupplyPoints()
    this.getDataSupplyPoint()
    this.tab = this.tabTIAMonitoring
    setTimeout(() => { this.showLoadingPlots(false) }, 500)
    setTimeout(() => { this.showLoading(false) }, 700)
  },
  methods: {
    async getListSupplyPoints () {
      const id = this.$store.state.TIA.TIAMap.stationSelected      
      this.supplyPoints = await getSupplyPoints(id)
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.supplyPointSelectedName', value: this.supplyPoints[0].name })
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.supplyPointSelectedId', value: this.supplyPoints[0].id })
    },
    async getDataSupplyPoint () {
      try {
        const id = this.$store.state.TIA.TIAMonitoring.supplyPointSelectedId
        const period = this.$store.state.TIA.TIAMonitoring.periodSelected

        let dateStart
        let dateEnd
        let timeMiddle = null
        let dateTimeMachine
        let hourTimeMachine 
        let ahead = false
        const timeMachine = window.sessionStorage.getItem('timeMachine')
        const fechaHoraActual = new Date()

        if (period === 'today') {
          dateStart = this.getLocalDateFormated(0, window.sessionStorage.getItem('dateMachine'))
          dateEnd = this.getLocalDateFormated(0, window.sessionStorage.getItem('dateMachine'))
          dateStart = this.checkTimeMachine(window.sessionStorage.getItem('dateMachine'), dateStart)
          dateEnd = this.checkTimeMachine(window.sessionStorage.getItem('dateMachine'), dateEnd)
          if(timeMachine!== null && timeMachine!== undefined){
            timeMiddle = `${dateStart}T${timeMachine}Z`
            hourTimeMachine = window.sessionStorage.getItem('timeMachine')
          }else{
                        
            dateTimeMachine = fechaHoraActual.toISOString().substr(0,10)
            hourTimeMachine = fechaHoraActual.toLocaleTimeString()
            timeMiddle = `${dateTimeMachine}T${hourTimeMachine}Z`
          }

        } else if (period === 'tomorrow') {
          dateStart = this.getLocalDateFormated(-1, window.sessionStorage.getItem('dateMachine'))
          timeMiddle = `${dateStart}T00:00:00Z`
          dateEnd = this.getLocalDateFormated(-1, window.sessionStorage.getItem('dateMachine'))
        } else if (period === 'yesterday') {
          dateStart = this.getLocalDateFormated(1, window.sessionStorage.getItem('dateMachine'))
          timeMiddle = `${dateStart}T23:59:00Z`
          dateEnd = this.getLocalDateFormated(1, window.sessionStorage.getItem('dateMachine'))
        } else if (period === 'ahead') {
          ahead = true
          if(timeMachine!== null && timeMachine!== undefined){
            hourTimeMachine = window.sessionStorage.getItem('timeMachine')
          }else{
            hourTimeMachine =fechaHoraActual.toLocaleTimeString()
          }
          dateStart = this.getLocalDateFormated(0, window.sessionStorage.getItem('dateMachine'))          
          timeMiddle = `${dateStart}T${hourTimeMachine}Z`
          dateEnd = this.getLocalDateFormated(-1, window.sessionStorage.getItem('dateMachine'))

        }

        let timeStart
        let timeEnd
        if (ahead){
          timeStart = `${dateStart}T${hourTimeMachine}Z`
          timeEnd = `${dateEnd}T${hourTimeMachine}Z`
        }else{
          timeStart = `${dateStart}T00:00:00Z`
          timeEnd = `${dateEnd}T23:59:00Z`
        }
        this.doeSupplyPointPlot = await getDoeSupplyPointPlot(id, timeStart, timeEnd)
        this.monitorSupplyPointPlot = await getMonitorSupplyPointPlot(id, timeStart, timeEnd)
   
        console.log( this.doeSupplyPointPlot)


      } catch (error) {
        console.log(error)
        this.error = true
      }
    },

    async getLogs (timeStart,timeEnd) {
      const id = this.$store.state.TIA.TIAMap.stationSelected
      // this.events = await getMonitorLogs(id, timeStart,timeEnd)
      this.events = this.events.map(objeto => ({ ...objeto, solutions: 'Solutions' }))
    },
    showLoading (show) {
      this.loadingSelf.isVisible = show
    },
    showLoadingPlots (show) {
      this.loadingPlots.isVisible = show
    },
    clickTabKPIs () {
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.tabTIAMonitoring', value: 0 })
    },
    clickTabEvents () {
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.tabTIAMonitoring', value: 1 })
    }
  }
}
</script>

<style >
.tia-monitoring-kpis{
  margin-top: 0px;
}
.splitpanes--vertical > .splitpanes__splitter {
  background-color: #333;
  position: relative;
  width: 2px;
  border-right: 2px solid rgba(238, 238, 238, 0.2);
}
.splitpanes--horizontal > .splitpanes__splitter {
  background-color: #333;
  position: relative;
  height:2px;
  border-top:2px solid rgba(238, 238, 238, 0.2);
  margin-top:-1px;
  }
  .v-tab {
    min-width: 59px !important;
    max-width: 1059px !important;
    margin-right: -14px !important;
    margin-left: -5px !important;
}
.v-card-text{
    line-height: 1.25rem;
    padding-top: 16px;
    padding-left: 1px;
    padding-right: 1px;
    padding-bottom: 1px;
}
.v-tab.v-tab {
    height: 25px;
}
div.v-card__text {
    padding: 0px;
}
</style>
